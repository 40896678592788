import "./App.css";
import moment from "moment";
import "moment-timezone";
import React, { useState, useEffect } from "react";
import Disclaimer from "./js/disclaimer";
import FAQ from "./js/faq";
import ContactUs from "./js/contact";
import PrivacyPolicy from "./js/privacy";
import AboutUs from "./js/about";
import { useLocation } from "react-router-dom";
import trackVisitor from "./utilities/tracker";
import DaySattaResult from "./js/daySattaResult";
import AdvertisementComponent from "./utilities/advertismentComponent";
import { Helmet } from "react-helmet";
const momenttz = require("moment-timezone");

function App() {
  // var currentDate = moment(new Date()).tz("Asia/Kolkata").format("lll");
  const location = useLocation();
  const isDisc = location.pathname.includes("/disclaimer");
  const isContact = location.pathname.includes("/contact");
  const isPrivacy = location.pathname.includes("/privacypolicy");
  const isAbout = location.pathname.includes("/about");
  const isFaq = location.pathname.includes("/faq");
  const todayDate = moment(new Date()).format("lll");
  // const currentTime = moment().format("HH:mm");
  const [data, setData] = useState([]); // State to store data fetched from backend
  const [datagame, setDataFor] = useState([]); // State to store processed data for display
  const [currentTime, setCurrentTime] = useState(moment().tz("Asia/Kolkata").format("HH:mm:ss"));

  // Update current time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().tz("Asia/Kolkata").format("HH:mm:ss"));
    }, 1000); // Update every second

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  var currentDate = moment(new Date()).tz("Asia/Kolkata").format("YYYY-MM-DD");
  var prevDate = moment(new Date())
    .subtract(1, "days")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");

  // const [gamedata, setGameData] = useState([]);

  function pageScroll() {
    const tableSection = document.getElementById("monthTable");
    if (tableSection) {
      tableSection.scrollIntoView({ behavior: "smooth" });
    }
  }
  useEffect(() => {
    trackVisitor();
  });

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);

  useEffect(() => {
    if (data?.length > 0) {
      const currentMoment = moment(currentTime, "HH:mm");
  
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;
  
        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "wait",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });
  
      const sortedProcessedData = processedData.sort((a, b) => 
        a.itemTime.diff(b.itemTime)
      );
  
      const availableResults = sortedProcessedData.filter((item) => item.isAvailable);
      const upcomingRecords = sortedProcessedData.filter((item) => !item.isAvailable);
  
      let recordsToDisplay = [];
  
      if (availableResults.length > 0) {
        recordsToDisplay = [...availableResults];
  
        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }
  
      if (recordsToDisplay.length > 3) {
        recordsToDisplay = recordsToDisplay.slice(-3).reverse();
      }
  
      // Move "wait" items to the top
      recordsToDisplay.sort((a, b) => (a.result === "wait" ? -1 : 1));
  
      setDataFor(recordsToDisplay);
    }
  }, [data, currentTime]);


  return (
    <div className="App">

      {/* seo setup start */}
      <Helmet>
        <title>App - Satta Fast King</title>
        <meta name="description" content="This is the page for getting all details." />
        <meta name="Keywords"
          content="sattakingreal, satta king real, sattaking real, Satta King, Satta King live result, Satta king online result, Satta king online, Satta king result today, Gali result, Desawar result, Faridabad result, Gaziyabad result, Satta matka king, Satta Bazar, Black satta king, Satta king 2017, satta king 2018, Gali Leak Number, Gali Single Jodi, Black Satta Result, Desawar Single Jodi, Satta king up, Satta king desawar, Satta king gali, Satta king 2019 chart, Satta baba king, Satta king chart, Gali live result, Disawar live result, Satta Number, Matka Number, Satta.com, Satta Game, Gali Number, Delhi Satta king," />
        <link rel="canonical" href="https://sattakingreal.co/" />
      </Helmet>
      {/* seo setup end */}
      <div class="button-container">
        <a href="/" class="rounded-button">
          HOME🏠
        </a>
        <a href="/" class="rounded-button">
          SATTA KING👑
        </a>
        {/* <a href="/" class="rounded-button">CHART</a> */}
        <a onClick={pageScroll} className="rounded-button">
          CHART💹
        </a>
      </div>
      <marquee className="text-warning">
        Satta king, Sattaking, Satta king, Satta result, Satta king result,
        Satta king live, Satta king online , Desawar result, Gali result,
        Faridabad result, Gaziyabad result, Satta king chart, Desawar record
        chart, Gali record chart, Faridabad record chart, Gaziyabad record chart
      </marquee>
      <div className="col-12 text-center header-heading">
        <h6>🎯SATTA KING, SATTAKING, SATTA RESULT🎯</h6>
        <h1>SATTA KING</h1>
      </div>
      <div className="banner bg-success text-white p-3 sattaReal mt-5">
        {/* <h5 className="">{todayDate}</h5> */}
        <p className="text-center" style={{fontSize:'24px',color:'yellow',fontWeight:'bold'}}>🕗Current Time: {currentTime}</p> {/* Display running current time */}
        {/* <h5 className="">{todayDate}</h5> */}
        <p className="text-center"  style={{fontSize:'24px',color:'yellowgreen',fontWeight:'bold'}}> {currentDate}</p> {/* Display current date */}
        {datagame?.map((todayData, index) => (
          <div key={index} className="game">
            <h3 className="mb-0">{todayData?.gameName}</h3>
            <h5 className=" lh-1 blinking-text" style={{fontSize:'26px'}}>{todayData?.result || ""}</h5>
          </div>
        ))}
      </div>
      <AdvertisementComponent type='odd' />
      
      <AdvertisementComponent type='even' />
      <DaySattaResult dayGameData={data} />

      {isDisc && <Disclaimer style={{ display: "none" }} />}
      {isContact && <ContactUs style={{ display: "none" }} />}
      {isPrivacy && <PrivacyPolicy style={{ display: "none" }} />}
      {isAbout && <AboutUs style={{ display: "none" }} />}
      {isFaq && <FAQ style={{ display: "none" }} />}

    </div>
  );
}

export default App;
